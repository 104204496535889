import '../../../shared/styles/index.css';
import '../../../shared/styles/cookieconsent.css';
import * as Sentry from '@sentry/browser';
import {
  CartContextProvider,
  getDefaultCountryFromLocale,
  CountryProvider,
  getLocaleFromContext,
  I18nextProvider,
  resolveStripeLocale,
  resolveZendeskLocale,
  SitenameProvider,
  getCountryParamFromContext,
  Country,
} from '@lilbit/shared';
import PropTypes from 'prop-types';
import Zendesk from 'react-zendesk';
import { ZendeskAPI } from 'react-zendesk';
import { zendeskSettings } from '../lib/zendesk';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import CookiesConsent from '@lilbit/shared/src/components/cookiesConsent';
import { parseCookies } from 'nookies';
import {
  tiktokPixelScript,
  snapchatPixelScript,
  klaviyoScript,
  circlewiseScript,
  googleAnalyticsScript,
} from '../components/appScripts';
import appConfig from '@lilbit/shared/src/lib/app-config';
Sentry.init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: '', //This had to be '' pointing to this Github issue https://github.com/mozilla/blurts-server/pull/1268
});

const fonts = [
  {
    cssSrc:
      'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600',
  },
];

function MyApp({ Component, pageProps, err, commonData }) {
  const { locale, localeResource, country, mainProductPrices } = commonData;
  const [scriptsLoaded, setScriptsLoaded] = useState(false);
  const [stripePromise, setStripePromise] = useState(
    parseCookies()['cookie-consent'] === 'accepted'
      ? loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY, {
          locale: resolveStripeLocale(locale),
        })
      : null
  );
  const [marketingCookieConsentGiven, setMarketingCookieConsentGiven] =
    useState(
      parseCookies()['cookie-consent-marketing'] === 'accepted'
        ? true
        : parseCookies()['cookie-consent-marketing'] === undefined
        ? undefined
        : false
    );
  const [cookieConsentGiven, setCookieConsentGiven] = useState(
    parseCookies()['cookie-consent'] === 'accepted'
      ? true
      : parseCookies()['cookie-consent'] === undefined
      ? undefined
      : false
  );
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    window.onload = () => {
      setPageLoaded(true);
    };
  });

  const handleStateChange = () => {
    setCookieConsentGiven(
      parseCookies()['cookie-consent'] === 'accepted' ? true : false
    );
    setMarketingCookieConsentGiven(
      parseCookies()['cookie-consent-marketing'] === 'accepted' ? true : false
    );
    const stripe = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY, {
      locale: resolveStripeLocale(locale),
    });
    setStripePromise(stripe);
  };

  //construct pageName. Check if lang is in query, then append category if it exists, then post if it exists
  const router = useRouter();
  /* Converge page load tracking */
  useEffect(() => {
    if (window.cvg !== 'function') return;
    const handleRouteChange = (url) => {
      //@ts-ignore. cvg is defined through the converge script
      cvg({ method: 'track', eventName: '$page_load' });
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (marketingCookieConsentGiven) {
      const tiktokPixel = document.createElement('script');
      tiktokPixel.textContent = tiktokPixelScript;
      tiktokPixel.defer = true;
      document.head.appendChild(tiktokPixel);

      const snapchatPixel = document.createElement('script');
      snapchatPixel.textContent = snapchatPixelScript;
      snapchatPixel.defer = true;
      document.head.appendChild(snapchatPixel);

      const circlewise = document.createElement('script');
      circlewise.textContent = circlewiseScript;
      circlewise.defer = true;
      document.head.appendChild(circlewise);

      const convergePix = document.createElement('script');
      convergePix.src = 'https://static.runconverge.com/pixels/MrsLQe.js';
      convergePix.defer = true;
      document.head.appendChild(convergePix);

      const klaviyo = document.createElement('script');
      klaviyo.src =
        'https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=X89nD7';
      klaviyo.defer = true;
      document.head.appendChild(klaviyo);

      const klaviyoS = document.createElement('script');
      klaviyoS.textContent = klaviyoScript;
      klaviyoS.defer = true;
      document.head.appendChild(klaviyoS);

      const googleTagManager = document.createElement('script');
      googleTagManager.textContent = googleAnalyticsScript;
      googleTagManager.defer = true;
      document.head.appendChild(googleTagManager);

      setScriptsLoaded(true);
      return () => {
        document.head.removeChild(tiktokPixel);
        document.head.removeChild(snapchatPixel);
        document.head.removeChild(circlewise);
        document.head.removeChild(convergePix);
        document.head.removeChild(klaviyo);
        document.head.removeChild(klaviyoS);
        document.head.removeChild(googleTagManager);
      };
    }
  }, [marketingCookieConsentGiven, scriptsLoaded]);

  return (
    <SitenameProvider name="lilcat" cookieConsentGiven={cookieConsentGiven}>
      <I18nextProvider locale={locale} localeResource={localeResource}>
        <CartContextProvider>
          <CountryProvider country={country}>
            <Elements stripe={stripePromise} options={{ fonts: fonts }}>
              {pageLoaded && cookieConsentGiven === undefined && (
                <CookiesConsent onStateChange={handleStateChange} />
              )}
              <Component {...pageProps} err={err} />
            </Elements>
          </CountryProvider>
        </CartContextProvider>
      </I18nextProvider>
    </SitenameProvider>
  );
}

MyApp.getInitialProps = async function ({ ctx }) {
  try {
    const locale = getLocaleFromContext(ctx);

    /* Get country from urlparams */
    let country: Country;
    const countryParam = getCountryParamFromContext(ctx);
    if (!countryParam) {
      country = getDefaultCountryFromLocale(locale);
    } else {
      country = appConfig.countries.find((c) => c.cca2 === countryParam);
    }
    /**
     * Get shared data for all pages
     */

    return {
      commonData: {
        locale,
        country,
      },
    };
  } catch (error) {
    console.error(error);
    console.warn('Could not fetch common page data');

    // Fallback values
    return {
      commonData: {
        mainNavigation: [],
        tenant: {
          defaults: {
            currency: 'usd',
          },
        },
      },
    };
  }
};
MyApp.propTypes = {
  Component: PropTypes.object,
  pageProps: PropTypes.object,
  err: PropTypes.object,
  commonData: PropTypes.object,
};

export default MyApp;
